import { sendRequest } from '../rest';
import { authHandler } from './loginPage';
import { i18n } from '../mainLayout';
// import {createButton} from "../../components/buttons";
import { createLabel } from '../../components/fields';
import { createForm } from '../../components/panels';
import { createContainer } from '../../components/miscComponents';
// import {createComboCert} from "../components/components";

import { BUTTON_CLS, createButton } from '@UIkit/components/buttons/Buttons';
import { FIELD_CLS } from '@UIkit/components/fields/Base/Base';
import { createComboCerts } from '../components/ComboboxCerts/ComboboxCerts';

const createCertificateForm = function () {
	let selectedCert, loginBtn;

	let submit = function () {
		var values = edi.utils.collectFormValues(form);
		selectedCert = values.cert;
		errorLabel.setText('');
		loginBtn.setDisabled(false);

		let errorHandler = function (resp) {
			errorLabel.setText(i18n(resp?.typeError) || i18n('error.server'));
		};

		if (!selectedCert) {
			loginBtn.setDisabled(true);
			certPanel?.down('combo')?.markInvalid('');
			errorLabel.setText(i18n('select.login.cert'));
			return;
		}

		let authWithSign = function (signResult) {
			if (!signResult || signResult.error) {
				errorHandler();
			}
			let payload = Ext.encode({ signature: signResult.data });
			sendRequest(edi.rest.services.SSO.LOGIN_CERT, 'POST', payload)
				.then((resp) => authHandler(resp, form, errorHandler))
				.catch(errorHandler);
		};

		let signHash = function (resp) {
			let hash = resp.data?.data;
			if (!resp.success || !hash) {
				errorHandler(resp);
			}
			edi.sign.setSignature({
				content: hash,
				certificate: selectedCert,
				callback: authWithSign
			});
		};

		sendRequest(edi.rest.services.SSO.GET_HASH_FOR_SIGN, 'GET', null).then(signHash).catch(errorHandler);
	};

	let certPanel = createComboCerts({
		cls: [FIELD_CLS.big],
		margin: '32 0 0 0',
		fieldLabel: edi.i18n.getMessage('sso.certificate.title'),
		listeners: {
			select: function () {
				errorLabel.setText('');
				loginBtn.setDisabled(false);
			}
		}
	});

	loginBtn = createButton({
		margin: '24 0 0 0',
		cls: [BUTTON_CLS.primary],
		text: i18n('form.btn.login'),
		handler: submit,
		// cls: "edi-login-submit-button edi-ediweb-submit-button",
		// style: {
		// 	'display': 'block'
		// },
		formBind: true,
		disabled: true
	});
	var registerBtn = edi.constants.SSO_GLOBAL_VARIABLES.runInFrame
		? null
		: createButton({
				margin: '24 0 0 0',
				cls: [BUTTON_CLS.secondary],
				text: edi.i18n.getMessage('register.btn'),
				handler: function () {
					errorLabel.setText('');
					loginBtn.setDisabled(false);
					window.location.href = environment?.registerUrl || '/';
				}
				// cls: "edi-login-register-button edi-ediweb-submit-button",
				// width: "100%",
		  });

	let errorLabel = createLabel({
		cls: 'edi-login-error-label',
		listeners: {
			// Эвент который стрельнет при setText
			resize: (label) => {
				label.toggleCls('label-empty', !label.text);
				form.updateLayout();
			}
		}
	});
	let errorContainer = createContainer({
		cls: 'edi-login-error-container edi-login-form__label',
		items: [errorLabel]
	});

	let form = createForm({
		// cls: "edi-form edi-certificate-login-form",
		items: [certPanel, errorContainer, loginBtn, registerBtn]
	});
	return form;
};

export { createCertificateForm };
