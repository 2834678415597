const sendRequest = (url, method, payload, opts) =>
	new Promise((resolve, reject) => {
		let opts = Ext.merge(
			{
				suppressDefaultError: true,
				headers: {
					Authorization: 'EDI'
				}
			},
			opts
		);
		return edi.rest.sendRequest(url, method, payload, resolve, reject, null, opts);
	});

Ext.namespace('edi.rest');
Ext.apply(edi.rest.services, {
	SSO: {
		AUTHENTICATE: '/api/user/auth/authenticate',
		LOGIN: '/api/user/auth/login',
		LOGOUT: '/api/user/auth/logout',
		LOGIN_CERT: '/api/user/auth/certificate',
		GET_HASH_FOR_SIGN: '/api/user/auth/sign/data',
		REQUEST_RECOVERY: '/api/user/auth/password/recovery/{login}',
		SET_NEW_PASSWORD: '/api/user/auth/password_recovery/{key}',
		EMAIL_CONFIRMATION: '/api/user/auth/mail_confirm_doc/{key}',
		UNAUTHORIZED: '/api/client/user/set_password'
	},
	I18N: {
		SYSTEM: {
			TRANSLATION: {
				GROUP: {
					GET: '/api/public/translation/group/{groupName}/{lang}/{packageName}'
				}
			}
		}
	},
	MESSAGES: {
		RUSSIAN: {
			GET: 'json/messages.ru.json'
		},
		ENGLISH: {
			GET: 'json/messages.en.json'
		},
		JAPAN: {
			GET: 'json/messages.jp.json'
		},
		ESTONIAN: {
			GET: 'json/messages.ee.json'
		},
		LATVIAN: {
			GET: 'json/messages.lv.json'
		},
		LITHUANIAN: {
			GET: 'json/messages.lt.json'
		},
		UKRAINIAN: {
			GET: 'json/messages.ua.json'
		}
	},
	USER: {
		PERMISSIONS: { GET: 'dummy' },
		ORGANIZATION: { GET: 'dummy' },
		LOGIN: { POST: 'dummy' },
		LOGOUT: { POST: 'dummy' }
	},
	ADMIN: {
		I18N: {
			TRANSLATION: {
				INNER_LOADING: {
					POST: '/api/system/translation/inner/loading'
				}
			}
		},
		TRANSLATIONS: {
			GROUP: {
				GET: edi.rest.prfx.a + 'translation/group'
			},
			VERSION: {
				GET: edi.rest.prfx.a + 'translation/version'
			},
			RESET: {
				PUT: edi.rest.prfx.a + 'translation/group/cache/reset'
			}
		}
	}
});
edi.rest.services.USER = edi.rest.services.USER || {};
edi.rest.services.USER.CHECK_AUTH_TOKEN = edi.rest.services.USER.CHECK_AUTH_TOKEN || {};
edi.rest.services.USER.CHECK_AUTH_TOKEN.POST = '';
export { sendRequest };
