import '../../../src/main/webapp/themes/ext-theme-core/icon-fonts.css';
import '../../../src/main/webapp/themes/ext74-neptune/MyApp-all.css';
import '../../../src/main/webapp/themes/core/client.scss';
import '../../../src/main/webapp/themes/ext-theme-core/ext-74-with-UIkit.scss';
import '../../../src/main/webapp/themes/sso/styles.scss';

import "core-js/stable";
import '../../../src/main/webapp/js/constants.js';
import '@UIkit/index.js';
import '../../../src/main/webapp/js/sso/configuration.js';
import '../../../src/main/webapp/js/extensions.js';
import '../../../src/main/webapp/js/sso/sign.js';
import '../../../src/main/webapp/js/sso/loading.js';
import '../../../src/main/webapp/js/core.js';
import '../../../src/main/webapp/js/models.js';
import '../../../src/main/webapp/js/core/models.js';
import '../../../src/main/webapp/js/sso/models.js';
import '../../../src/main/webapp/js/columns.js';
import '../../../src/main/webapp/js/permissions.js';
import '../../../src/main/webapp/js/store.js';
import '../../../src/main/webapp/js/rest.js';
import '../../../src/main/webapp/js/sso/rest.js';
import '../../../src/main/webapp/js/i18n.js';
import '../../../src/main/webapp/js/utils.js';
import '../../../src/main/webapp/js/renderers.js';
import '../../../src/main/webapp/js/sso/renderers.js';
import '../../../src/main/webapp/js/components/index.js';
import '../../../src/main/webapp/js/core/specialComponents/index.js';
import '../../../src/main/webapp/js/signature/sign.js';
import '../../../src/main/webapp/js/signature/cryptopro/sign.js';
import './startApp.js';
