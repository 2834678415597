import { createRecoveryRequestForm } from './recoveryRequestForm.js';
import { createNewPasswordForm } from './newPasswordForm.js';
import { createPanel } from '../../components/panels';
import { getRequireNewPassword } from '../mainLayout';

const createPasswordRecoveryPage = function () {
	let key = edi.constants.SSO_GLOBAL_VARIABLES.key;
	let requireNewPassword = getRequireNewPassword();
	return createPanel({
		cls: 'edi-form edi-login-form',
		width: edi.constants.DEFAULT.LOGIN_FORM_WIDTH,
		minWidth: edi.constants.DEFAULT.LOGIN_FORM_WIDTH,
		minHeight: 308,
		bodyPadding: '0',
		margin: '0',
		items: [
			key || requireNewPassword ? createNewPasswordForm(key, requireNewPassword) : createRecoveryRequestForm()
		]
	});
};

export { createPasswordRecoveryPage };
