edi.constants.LOCALIZATION_PACKAGE_NAME = 'Sso';
edi.constants.DEFAULT.VIEWPORT.minWidth = 400;
edi.constants.DEFAULT.VIEWPORT.minHeight = 400;
edi.constants.DEFAULT.LOGIN_FORM_WIDTH = 328;

edi.constants.DEFAULT.BACKGROUND.ON_START = true;
edi.constants.DEFAULT.HIDE_LOGIN_FOOTER = false;
edi.constants.DEFAULT.BACKGROUND.ROTATION = 'LIST';
edi.constants.DEFAULT.BACKGROUND.CLASS_NAME_PREFIX = 'edi-viewport-background-';
edi.constants.DEFAULT.BACKGROUND.IMG_LIST = ['ediweb'];
edi.constants.DEFAULT.SHOW_LOGIN_HEADER = true;
edi.constants.DEFAULT.LANGUAGE = buildVersion.defaultLanguage || 'RUSSIAN';
edi.constants.AVAILABLE_LANGUAGES = {
	RUSSIAN: 'RU',
	ENGLISH: 'EN'
};

edi.constants.DEFAULT.COMPONENTS_SETTINGS.TAB_PANEL_CLASS = 'edi-tab-bar edi-ediweb-tab-bar';

Ext.apply(edi.constants.DEFAULT.HEADER_CONFIG, {
	height: 72
});

Ext.apply(edi.constants.DEFAULT.COMPONENTS_SETTINGS, {
	SIDEBAR: {
		WIDTH: 425
	}
});

Ext.merge(edi.constants, {
	CERTIFICATE: {
		ALLOW_ACTIVE: false
	},
	SSO: {
		AUTH_METHODS: {
			HTTP_LOGIN: 'HTTP_LOGIN',
			CERTIFICATE: 'CERTIFICATE'
		}
	}
});
