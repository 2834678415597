/**
 * @author Vsevolod Liapin
 * Column configurations for grids
 */
Ext.namespace("edi.columns");

edi.columns = new function() {

	this.config = {};

	var replaceMap = {
		renderer: "edi.renderers",
		validator: "edi.methods",
		data: "edi.stores",
		text: edi.core.getMessage,
		tooltip: edi.core.getMessage
	};

	var replaceNameOnMethod = function(columnConfig) {
		for (var key in columnConfig) {
			if (columnConfig.hasOwnProperty(key)) {
				if ('object' == typeof columnConfig[key] && key != 'columns') {
					replaceNameOnMethod(columnConfig[key]);
				}
				else if ('string' == typeof columnConfig[key] && replaceMap.hasOwnProperty(key)) {
					var convert;
					if ('string' == typeof replaceMap[key]) {
						convert = edi.utils.getObjectProperty(edi.utils.getObjectProperty(window, replaceMap[key]), columnConfig[key]);
					}
					else if ('function' == typeof replaceMap[key]) {
						convert = replaceMap[key](columnConfig[key]);
					}
					columnConfig[key] = convert ? convert : undefined;
				}
			}
		}
	};

	this.addColumns = function(config) {
		var configColumns = {};
		for (var key in config) {
			if (config.hasOwnProperty(key) && 'object' == typeof config[key]) {
				if (config[key].hasOwnProperty('parents') && config[key].hasOwnProperty('config')
					&& 'string' == typeof config[key]['parents'] && 'object' == typeof config[key]['config'] && this.config.hasOwnProperty(config[key]['parents'])) {
					var parentConfig = Ext.clone(this.config[config[key]['parents']]);
					configColumns[key] = Ext.Object.merge(parentConfig, config[key]['config']);
				}
				else {
					configColumns[key] = config[key];
				}
			}
		}

		Ext.Object.each(configColumns, function(key, configColumnsValue) {
			Ext.Object.each(configColumnsValue, function(key, column) {
				if (typeof column === 'object') {
					if (column && !column.hasOwnProperty('minWidth')) {
						column['minWidth'] =  edi.constants.DEFAULT.COMPONENTS_SETTINGS.GRID.COLUMN_MIN_WIDTH || 130;
					}
				}
			})
		})

		this.config = Ext.Object.merge(this.config, configColumns);

	};

	/**
	 * process column titles with messages
	 * @param   {Array}     columnsConfig
	 * @returns {Array}
	 */
	this.processConfig = function(columnsConfig) {
		columnsConfig = Ext.clone(columnsConfig);
		var retArr = [];
		var order = 1;
		for (var columnKey in columnsConfig) {
			if (columnsConfig.hasOwnProperty(columnKey) && columnsConfig[columnKey]) {
				columnsConfig[columnKey].key = Ext.clone(columnsConfig[columnKey].text);
				columnsConfig[columnKey].order = columnsConfig[columnKey].hasOwnProperty("order") ? columnsConfig[columnKey].order : order++;
				replaceNameOnMethod(columnsConfig[columnKey]);
				if (columnsConfig[columnKey].columns) {
					columnsConfig[columnKey].columns = this.processConfig(columnsConfig[columnKey].columns);
				}
				retArr.push(columnsConfig[columnKey]);
			}
		}
		return retArr;
	};

	/**
	 * get columns config
	 * @param   {String}     name    name of column layout generation method
	 * @param   {function}   handler  preprocess column object
	 * @returns {Array}
	 */
	this.get = function(name, handler) {
		var result = this.getDefault(name, handler);

		// Restore column settings
		if (result.length && edi.constants.COLUMN_CONFIG_SAVE_ENABLED) {
			var columnsConfig = edi.utils.getData("c." + name);
			if (columnsConfig) {
				var isChanged = false, isOrder = false, column, index, col, ind, config, conf, i, j;
				for (i = 0; i < result.length; i++) {
					column = result[i];
					index = (column.dataIndex ? column.dataIndex : column.key).split(".").join("-");
					if (column.columns) {
						for (j = 0; j < column.columns.length; j++) {
							col = column.columns[j];
							ind = (col.dataIndex ? col.dataIndex : col.key).split(".").join("-");
							if (!columnsConfig.hasOwnProperty(ind)) {
								isChanged = true;
								break;
							}
						}
					}
					if (isChanged || !columnsConfig.hasOwnProperty(index)) {
						isChanged = true;
						break;
					}
				}
				for (i = 0; i < result.length; i++) {
					column = result[i];
					index = (column.dataIndex ? column.dataIndex : column.key).split(".").join("-");
					config = columnsConfig[index];
					if (config && (config.flex == "NaN" || config.width == "NaN")) {
						isChanged = true;
						break;
					}
				}
				if (!isChanged) {
					var restore = function(column, config) {
						var isOrder = false;
						if (config.flex) {
							undefined !== column.width ? delete column.width : null;
							var flex = parseFloat(config.flex);
							flex ? column.flex = flex : null;
						}
						else if (config.width) {
							var width = parseInt(config.width, 10);
							width ? column.width = width : null;
						}
						if (!column.flex && !column.width) {
							column.flex = 1;
						}
						config.visible ? column.hidden = config.visible == "0" : null;
						if (undefined !== config.order) {
							column.order = parseInt(config.order, 10);
							isOrder = true;
						}
						return isOrder;
					};
					for (i = 0; i < result.length; i++) {
						column = result[i];
						index = (column.dataIndex ? column.dataIndex : column.key).split(".").join("-");
						config = columnsConfig[index];
						isOrder = restore(column, config) ? true : isOrder;
						if (column.columns) {
							for (j = 0; j < column.columns.length; j++) {
								col = column.columns[j];
								ind = (col.dataIndex ? col.dataIndex : col.key).split(".").join("-");
								conf = columnsConfig[ind];
								isOrder = restore(col, conf) ? true : isOrder;
							}
						}
					}
					if (isOrder) {
						result.sort(sorter);
						for (i = 0; i < result.length; i++) {
							if (result[i].columns) {
								result[i].columns.sort(sorter);
							}
						}
					}
				}
				else {
					edi.utils.setData("c." + name, "");
				}
			}
		}
		return result;
	};

	/**
	 * get default columns config
	 * @param   {String}     name    name of column layout generation method
	 * @param   {function}   handler  preprocess column object
	 * @returns {Array}
	 */
	this.getDefault = function(name, handler) {
		var result = [];
		if (name && "object" == typeof this.config[name]) {
			result = this.processConfig((handler && "function" == typeof handler) ? handler(Ext.clone(this.config[name])) : this.config[name]);
			var versionData = [];

			var i, j;
			for (i = 0; i < result.length; i++) {
				versionData[i] = Ext.clone(result[i]);
				versionData[i].text = "";
				if (result[i].columns) {
					versionData[i].columns = [];
					for (j = 0; j < result[i].columns.length; j++) {
						versionData[i].columns[j] = Ext.clone(result[i].columns[j]);
						versionData[i].columns[j].text = "";
					}
				}
			}
			var currentVersion = edi.utils.sha1(Ext.encode(versionData));
			var moveColumn = function(cm, oldindex, newindex) { //Prevent moving sub columns
				cm.moveColumn(newindex, oldindex);
			};
			for (i = 0; i < result.length; i++) {
				result[i].columnsName = name;
				result[i].columnsVersion = currentVersion;
				result[i].cName = result[i].dataIndex || result[i].key;
				result[i].cName = result[i].cName.split(".").join("-");
				if (result[i].columns) {
					versionData[i].columns = [];

					for (j = 0; j < result[i].columns.length; j++) {
						result[i].columns[j].columnsName = name;
						result[i].columns[j].columnsVersion = currentVersion;
						result[i].columns[j].cName = result[i].columns[j].dataIndex || result[i].columns[j].key;
						result[i].columns[j].cName = result[i].columns[j].cName.split(".").join("-");
						result[i].columns[j].listeners = {
							columnmoved: moveColumn
						};
						result[i].columns[j].hideable = false;
						result[i].columns[j].locked = true;
						result[i].columns[j].menuDisabled = true;
					}
				}
			}
			result.sort(sorter);
		}
		return result;
	};

	var sorter = function(a, b) {
		return a.order - b.order;
	};
}();
