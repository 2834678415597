import { changePageTo, i18n, PAGES, getLogin, LAYOUT } from '../mainLayout';
import { sendRequest } from '../rest';
import { createLabel } from '../../components/fields';
import { createForm, createPanel } from '../../components/panels';
import { createContainer } from '../../components/miscComponents';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons/Buttons';
import { createPasswordField } from '@UIkit/components/fields/Password/Password';
import { FIELD_CLS } from '@UIkit/components/fields/Base/Base';

const createNewPasswordForm = function (key, requireNewPassword) {
	let passwordField1,
		passwordField2,
		requirementItems,
		requirements = [
			{
				text: edi.i18n.getMessage('sso.password.only.latin.capital.and.lowercase'),
				matcher: /^(?=.*[a-z])(?=.*[A-Z])(?!.*[а-яА-Я])/
			},
			{
				text: edi.i18n.getMessage('sso.password.min.symbols', [10]),
				matcher: /.{10,}/
			},
			{
				text: edi.i18n.getMessage('password.have.number'),
				matcher: /\d+/
			},
			{
				text: edi.i18n.getMessage('password.have.special.character'),
				matcher: /[!@#$%]+/
			},
			{
				text: edi.i18n.getMessage('passwords.match'),
				matcher: function (value1, value2) {
					return value1?.length > 0 && value1 === value2;
				}
			}
		];
	let isValid = false;
	const cardPanel = LAYOUT.cardPanel;

	let sendNewPassword = function () {
		if (!isValid) {
			return;
		}
		errorLabel.setText('');
		cardPanel.setLoading(true);

		let errorHandler = function (resp) {
			cardPanel.setLoading(false);

			if (resp?.typeError === 'controller.user.password.repeat.for.this.credential.of.user') {
				recoverBtn.setDisabled(true);
				errorLabel.setText(i18n(resp?.typeError) || i18n('password.recovery.failed'));
			} else {
				changePageTo(PAGES.errorRecoveryPage);
			}
		};
		let values = edi.utils.collectFormValues(form);
		let url = edi.utils.formatString(edi.rest.services.SSO.SET_NEW_PASSWORD, { key });
		let payload = Ext.encode({ newPassword: values.password });
		sendRequest(url, 'PUT', payload)
			.then((resp) => {
				if (!resp || !resp.success) {
					errorHandler(resp);
					return;
				}
				cardPanel.setLoading(false);
				changePageTo(PAGES.successRecoveryPage);
			})
			.catch(errorHandler);
	};

	let firstChangePassword = function () {
		if (!isValid) {
			return;
		}
		errorLabel.setText('');
		cardPanel.setLoading(true);

		let errorHandler = function (resp) {
			cardPanel.setLoading(false);
			if (resp?.typeError === 'controller.user.password.repeat.for.this.credential.of.user') {
				recoverBtn.setDisabled(true);
				errorLabel.setText(i18n(resp?.typeError) || i18n('password.recovery.failed'));
			} else {
				changePageTo(PAGES.errorRecoveryPage);
			}
		};
		let login = getLogin();
		let values = edi.utils.collectFormValues(form);
		let payload = Ext.encode({
			login: login,
			password: values.password,
			requireNewPassword: false
		});
		sendRequest(edi.rest.services.SSO.UNAUTHORIZED, 'POST', payload)
			.then((resp) => {
				if (!resp || !resp.success) {
					errorHandler(resp);
					return;
				}
				changePageTo(PAGES.successRecoveryPage);
			})
			.catch(errorHandler);
	};

	let validatePasswords = function () {
		let passwordValue1 = passwordField1.getValue();
		let passwordValue2 = passwordField2.getValue();
		let validCount = 0;

		requirementItems.forEach((item) => {
			let check = false;
			if (typeof item.matcher === 'function') {
				check = item.matcher(passwordValue1, passwordValue2);
			} else if (item.matcher instanceof RegExp) {
				check = passwordValue1.match(item.matcher);
			}
			item[check ? 'addCls' : 'removeCls']('requirements-pas-success');
			validCount += check ? 1 : 0;
		});

		passwordField1.isValid(false);

		isValid = validCount === requirementItems.length;
		recoverBtn.setDisabled(!isValid);
	};

	const subtitleLabel = edi.components.createLabel({
		cls: 'edi-ediweb-login-subtitle',
		text: edi.i18n.getMessage('sso.password.recovery.new.password')
	});

	const createRecoPasswordField = function (fieldLabel, name, config) {
		return createPasswordField(
			Ext.apply(
				{
					cls: [FIELD_CLS.big],
					fieldLabel: edi.i18n.getMessage(fieldLabel),
					name,
					maxLength: 20,
					listeners: {
						change: validatePasswords
					}
				},
				config
			)
		);
	};

	passwordField1 = createRecoPasswordField('column.password.new', 'password', {
		margin: '32 0 0 0'
	});
	passwordField2 = createRecoPasswordField('column.password.new2', 'password.new2', {
		margin: '16 0 0 0'
	});

	let createRequirementsPanel = function () {
		requirementItems = requirements.map(({ text, matcher }) =>
			createButton({
				text: edi.i18n.getMessage(text),
				baseCls: 'requirements-pas',
				glyph: edi.constants.ICONS.DONE,
				matcher
			})
		);

		return createContainer({
			padding: '0 0 16 0',
			items: requirementItems
		});
	};

	let successLabel = createContainer({
		hidden: true,
		items: [
			createLabel({
				text: i18n('sso.password.recovery.new.password.saved')
			})
		]
	});

	let recoverBtn = createButton({
		margin: '24 0 0 0',
		cls: [BUTTON_CLS.primary],
		text: i18n('form.btn.save'),
		handler: requireNewPassword ? firstChangePassword : sendNewPassword,
		disabled: true
	});

	let errorLabel = createLabel({
		cls: 'edi-login-error-label',
		text: ''
	});
	let errorContainer = createContainer({
		cls: 'edi-login-error-container',
		items: [errorLabel]
	});

	let logo = createContainer({
		cls: 'edi-ediweb-header-logo'
	});

	let form = createForm({
		items: [
			logo,
			subtitleLabel,
			createContainer({
				layout: {
					type: 'vbox',
					align: 'stretch'
				},
				items: [
					passwordField1,
					passwordField2,
					errorContainer,
					createRequirementsPanel(),
					recoverBtn,
					successLabel
				]
			})
		]
	});
	return form;
};

export { createNewPasswordForm };
