import { createHttpLoginForm } from './httpLoginForm.js';
import { createCertificateForm } from './certificateLoginForm.js';
import { changePageTo, getRequireNewPassword, i18n, PAGES, setRequireNewPassword } from '../mainLayout';
import { createTabPanel, createTab } from '@UIkit/components/tab/index';
import { createContainer, createPanel } from '@UIkit/components/panels';

const authHandler = function (resp, form, errorHandler) {
	let data = resp.data || {
		nextChainStepRequired: false,
		ediAuthToken: null,
		nextChainStep: []
	};
	if (!data.success) {
		if (typeof errorHandler === 'function') {
			errorHandler(resp);
		} else {
			form.setLoading(false);
		}
		return;
	}

	form.setLoading(false);
	if (data.nextChainStepRequired) {
		changePageTo(PAGES.login);
		PAGES.login.switchToNextStep(data.nextChainStep[0]);
	} else if (data.ediAuthToken) {
		//если мы успешно залогинились но нужно сменить пароль
		let afterSuccessfulLogin = function () {
			//если ссо запущен в iframe, значит никуда редиректить ненужно - внешняя система сама закроет iframe
			//просто отправим ей новый токен
			if (edi.constants.SSO_GLOBAL_VARIABLES.runInFrame === true) {
				let pathArray = edi.constants.SSO_GLOBAL_VARIABLES.url.split('/');
				let protocol = pathArray[0];
				let host = pathArray[2];
				window.parent.postMessage({ newAuthToken: data.ediAuthToken }, `${protocol}//${host}`);
			} else {
				let url = edi.constants.SSO_GLOBAL_VARIABLES.url;
				if (url && !edi.constants.SSO_GLOBAL_VARIABLES.noRedirect) {
					url = new URL(url);
					url.searchParams.append('token', data.ediAuthToken);
					window.location.href = url;
				} else {
					edi.constants.SSO_GLOBAL_VARIABLES.noRedirect = false;
					changePageTo(PAGES.successAuthLanding);
				}
			}
		};

		let requireNewPass = data.requireNewPassword;
		setRequireNewPassword(data.requireNewPassword);

		if (requireNewPass) {
			changePageTo(PAGES.passwordRecovery);
		} else {
			afterSuccessfulLogin();
		}
	} else {
		changePageTo(PAGES.successAuthLanding);
	}
};

const createAllMethodsForm = function (panel) {
	let httpTab = createTab({
		title: i18n('login.form.http_auth_type'),
		closable: false,
		items: [createHttpLoginForm()]
	});

	let certTab = createTab({
		title: i18n('login.form.cert_auth_type'),
		closable: false,
		items: [createCertificateForm()]
	});

	let tabPanel = createTabPanel({
		margin: '32 0 0 0',
		items: [httpTab, certTab],
		activeTab: 0
	});

	return !edi.permissions.hasPermission('ENABLE_CERTIFICATE_AUTHORIZATION') ? createHttpLoginForm() : tabPanel;
};

const formsFactory = {
	[edi.constants.SSO.AUTH_METHODS.HTTP_LOGIN]: createHttpLoginForm,
	[edi.constants.SSO.AUTH_METHODS.CERTIFICATE]: createCertificateForm
};

const createLoginPage = function () {
	var subtitleLabel = edi.components.createLabel({
		cls: 'edi-ediweb-login-subtitle',
		text: edi.i18n.getMessage('sso.enter.title')
	});

	let logo = createContainer({
		cls: 'edi-ediweb-header-logo'
	});
	return createPanel({
		name: 'login',
		cls: 'edi-login-page-panel edi-login-form',
		width: edi.constants.DEFAULT.LOGIN_FORM_WIDTH,
		minWidth: edi.constants.DEFAULT.LOGIN_FORM_WIDTH,
		switchToNextStep(nextStep) {
			let me = this;
			me.removeAll();
			if (formsFactory[nextStep]) {
				me.add(formsFactory[nextStep]());
			} else {
				me.add(createAllMethodsForm());
			}
		},
		items: [logo, subtitleLabel, createAllMethodsForm()]
	});
};

export { createLoginPage, authHandler };
