import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';

Ext.namespace('edi.renderers');
Ext.merge(edi.renderers, {
	renderFooterContents: function (layout) {
		var html = '';
		var currentYear = new Date().getFullYear();
		if (layout.footer && layout.footer.body) {
			html += Ext.DomHelper.markup({
				role: 'presentation',
				cls: 'edi-ediweb-footer',
				children: [
					{
						role: 'presentation',
						tag: 'a',
						href: edi.i18n.getMessage('login.form.footer.help.desk.link'),
						target: '_blank',
						cls: 'edi-ediweb-footer-link',
						cn: {
							tag: 'span',
							html: '© Ediweb'
						}
					},
					{
						tag: 'span',
						html: `, 1999–${currentYear}`
					}
				]
			});

			layout.footer.body.setHtml(html);
		}
	},
	/**
	 * Renders contents of the header, after translations load
	 */
	renderHeaderContents: function (layout) {
		var html = '';
		if (layout.header && layout.header.body) {
			html += Ext.DomHelper.markup({
				role: 'presentation',
				tag: 'a',
				href: edi.i18n.getMessage('login.form.footer.help.desk.addr'),
				target: '_blank',
				cls: 'edi-login-footer-link',
				children: {
					tag: 'span',
					html: edi.i18n.getMessage('login.form.free.support')
				}
			});
			html += Ext.DomHelper.markup({
				role: 'presentation',
				tag: 'a',
				href: edi.i18n.getMessage('login.form.footer.help.desk.link'),
				target: '_blank',
				children: {
					cls: 'edi-login-footer-link edi-ediweb-edisoft-header'
				}
			});
			html += Ext.DomHelper.markup({
				role: 'presentation',
				tag: 'a',
				href: `tel:${edi.i18n.getMessage('ediweb.login.form.footer.phone')}`,
				target: '_blank',
				cls: 'edi-login-footer-link',
				children: {
					tag: 'span',
					html: edi.i18n.getMessage('ediweb.login.form.footer.phone')
				}
			});

			layout.header.body.setHtml(html);

			if (environment?.showLanguageSelector === true) {
				const languages = Object.entries(edi.constants.AVAILABLE_LANGUAGES).map(([lang, transl]) => ({
					cls: `lang-button-menu-item lang-${lang}`,
					text: transl,
					listeners: {
						click: function () {
							if (edi.i18n.getLanguage() !== lang) {
								edi.utils.setCookie(edi.constants.DEFAULT.LANGUAGE_COOKIE_NAME, lang);
								window.location.reload();
							}
						}
					}
				}));
				createButton({
					cls: ['lang-button', `lang-${edi.i18n.getLanguage()}`],
					renderTo: layout.header.body.id,
					menuAlign: 'tl-bl',
					menu: {
						cls: 'lang-button-menu',
						minWidth: 74,
						items: languages
					}
				});
			}
		}
	},
	dateFromMs: function (value) {
		if (value) {
			value = edi.utils.formatDate(parseFloat(value), edi.constants.DATE_FORMAT.FNS);
		}
		return value || '';
	}
});
