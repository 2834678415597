import { sendRequest } from '../rest';
import { authHandler } from './loginPage';
import { changePageTo, i18n, PAGES, LAYOUT, setLogin } from '../mainLayout';
// import {createButton} from "../../components/buttons";
import { createLabel } from '../../components/fields';
import { createForm } from '../../components/panels';
import { createContainer } from '../../components/miscComponents';
// import {createFieldsBlock} from "../components/components";
// import {createPasswordField} from "../components/fields";

import { createFieldBlock } from '@UIkit/components/panels/FieldBlock/FieldBlock';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons/Buttons';
import { createTextField } from '@UIkit/components/fields/Text/Text';
import { createPasswordField } from '@UIkit/components/fields/Password/Password';
import { FIELD_CLS } from '@UIkit/components/fields/Base/Base';

const createHttpLoginForm = function () {
	/**
	 * Sets login timeout cookie
	 * @param    {Number}    time    timestamp of last login form lock
	 */
	const setLoginTimeoutCookie = function (time) {
		time = time || new Date().getTime();
		var date = new Date(time + edi.constants.LOGIN_TIMEOUT);
		edi.utils.setCookie(
			edi.constants.LOGIN_TIMEOUT_COOKIE_NAME,
			edi.utils.base64.encode(Ext.encode(time)),
			undefined,
			date.getTime()
		);
	};
	/**
	 * Gets login timeout cookie
	 * @returns {*}
	 */
	const getLoginTimeoutCookie = function () {
		var cookie = edi.utils.getCookie(edi.constants.LOGIN_TIMEOUT_COOKIE_NAME);
		if (cookie) {
			cookie = edi.utils.base64.decode(cookie);
		}
		return cookie;
	};

	var loginInput, passwordInput, loginBtn;
	let wrongLoginAttempts = 0;
	const loginTimeout = getLoginTimeoutCookie();
	const currTime = new Date().getTime();
	const needBlockLogin = loginTimeout && currTime - loginTimeout < edi.constants.LOGIN_TIMEOUT;

	loginInput = createTextField({
		cls: [FIELD_CLS.big],
		margin: '32 0 0 0',
		// cls: "edi-login-form__field",
		fieldLabel: i18n('login.form.login'),
		name: 'login',
		// width: "100%",
		msgTarget: 'none',
		listeners: {
			change: () => {
				errorLabel.setText('');
				loginBtn.setDisabled(false);
			},
			specialkey: function (field, e) {
				if (e.getKey() === e.ENTER) {
					submit();
				}
			}
		}
	});

	const blockLogin = function (skipCookie) {
		const cardPanel = LAYOUT.cardPanel;
		skipCookie = 'boolean' == typeof skipCookie ? skipCookie : false;
		!skipCookie ? setLoginTimeoutCookie() : null;
		let leftTime = parseInt(
			(skipCookie ? edi.constants.LOGIN_TIMEOUT - (currTime - loginTimeout) : edi.constants.LOGIN_TIMEOUT) / 1000,
			10
		);
		const interval = Ext.interval(() => {
			leftTime -= 1;
			if (leftTime <= 0) {
				wrongLoginAttempts = 0;
				cardPanel.setLoading(false);
				Ext.uninterval(interval);
				errorLabel.setText('');
				loginInput.focus(false, 200);
			} else {
				errorLabel.setText(
					edi.utils.formatString(i18n('login.failed.qnt.times'), {
						qnt: edi.constants.LOGIN_ATTEMPTS,
						block: parseInt(edi.constants.LOGIN_TIMEOUT / 1000, 10)
					})
				);
			}
		}, 1000);
		cardPanel.setLoading(true);
	};

	let submit = function () {
		if (!loginInput.getValue() || !passwordInput.getValue()) {
			loginBtn.setDisabled(true);
			loginInput.markInvalid('');
			passwordInput.markInvalid('');
			errorLabel.setText(i18n('cant.authorize.cause.not.active.user'));
			return;
		}
		const cardPanel = LAYOUT.cardPanel;
		errorLabel.setText('');
		loginBtn.setDisabled(false);
		//накладываем маску на все окно
		cardPanel.setLoading(true);

		let errorHandler = function (resp) {
			cardPanel.setLoading(false);
			loginInput.markInvalid('');
			passwordInput.markInvalid('');
			loginBtn.setDisabled(true);
			wrongLoginAttempts++;
			if (wrongLoginAttempts >= 3) {
				errorLabel.setText(
					edi.utils.formatString(i18n('login.failed.qnt.times'), {
						qnt: edi.constants.LOGIN_ATTEMPTS,
						block: parseInt(edi.constants.LOGIN_TIMEOUT / 1000, 10)
					})
				);
				blockLogin();
			} else {
				errorLabel.setText(i18n(resp?.typeError) || i18n('error.server'));
			}
		};

		let payload = Ext.encode(form.getForm().getValues());
		setLogin(loginInput.getValue());

		let url = edi.utils.compileURL(edi.rest.services.SSO.LOGIN, {
			backParam: edi.constants.SSO_GLOBAL_VARIABLES.backParam
		});
		sendRequest(url, 'POST', payload)
			.then((resp) => authHandler(resp, cardPanel, errorHandler))
			.catch(errorHandler);
	};

	passwordInput = createPasswordField({
		cls: [FIELD_CLS.big],
		margin: '24 0 0 0',
		// cls: "edi-login-form__field edi-ediweb-password-field",
		fieldLabel: i18n('login.form.password'),
		emptyText: null,
		name: 'password',
		// width: '100%',
		msgTarget: 'none',
		listeners: {
			change: () => {
				errorLabel.setText('');
				loginBtn.setDisabled(false);
			},
			specialkey: function (field, e) {
				if (e.getKey() === e.ENTER) {
					submit();
				}
			}
		}
	});

	let passwordRecoveryLink = createFieldBlock({
		layout: 'fit',
		cls: 'edi-login-form__label',
		items: [
			createLabel({
				text: i18n('password.recovery.link'),
				cls: 'link-label edi-password-recovery-link',
				listeners: {
					render: function (comp) {
						comp.el.on('click', function () {
							errorLabel.setText('');
							changePageTo(PAGES.passwordRecovery);
						});
					}
				}
			})
		]
	});

	loginBtn = createButton({
		margin: '24 0 0 0',
		cls: [BUTTON_CLS.primary],
		// layout: 'container',
		text: i18n('sso.form.btn.login'),
		handler: submit
		// cls: "edi-login-submit-button edi-ediweb-submit-button",
		// width: "100%",
	});

	let errorLabel = createLabel({
		cls: 'edi-login-error-label',
		listeners: {
			//Эвент который стрельнет при setText
			resize: (label) => {
				label.toggleCls('label-empty', !label.text);
				form.updateLayout();
			}
		}
	});
	let errorContainer = createContainer({
		// cls: 'edi-login-error-container edi-login-form__label',
		items: [errorLabel]
	});

	let form = createForm({
		// cls: "edi-form edi-http-login-form",
		// margin: '0',
		// padding: '0',
		// bodyPadding: 0,
		layout: {
			type: 'vbox',
			align: 'stretch'
		},
		items: [
			loginInput,
			passwordInput,
			passwordRecoveryLink,
			errorContainer,
			loginBtn,
			edi.constants.SSO_GLOBAL_VARIABLES.runInFrame
				? null
				: createButton({
						margin: '16 0 0 0',
						cls: [BUTTON_CLS.secondary],
						text: edi.i18n.getMessage('register.btn'),
						handler: function () {
							errorLabel.setText('');
							window.location.href = environment?.registerUrl || '/';
						}
						// cls: "edi-login-register-button edi-ediweb-submit-button",
						// width: "100%",
				  })
		],
		listeners: {
			afterrender: (self) => {
				if (needBlockLogin) {
					blockLogin(true);
				} else {
					//Если форма должна быть заблокирована фокус на логин не нужен
					loginInput.focus(false, 200);
				}
			}
		}
	});
	return form;
};

export { createHttpLoginForm };
