edi.models.setModels({
	CERTIFICATE: {
		extend: 'edi.models.CERTIFICATE',
		fields: [
			{
				name: 'issuer'
				//type: 'object'
			},
			{
				name: 'serial',
				type: 'string'
			},
			{
				name: 'subject'
				//type: 'object'
			},
			{
				name: 'hash',
				type: 'string'
			},
			{
				name: 'dateFrom',
				type: 'date'
			},
			{
				name: 'dateTo',
				type: 'date'
			},
			{
				name: 'dateFromParsed',
				type: 'string'
			},
			{
				name: 'dateToParsed',
				type: 'string'
			},
			{
				name: 'cert'
				//type: 'object'
			},
			{
				name: 'version',
				type: 'string'
			},
			{
				name: 'isValid',
				type: 'boolean'
			},
			{
				name: 'availableDocTypesForSignature'
				//type: 'object'
			},
			{
				name: 'algorithm'
				//type: "object"
			},
			{
				name: 'display_value',
				type: 'string',
				convert: function (v, record) {
					var subject = record.get('subject');

					return edi.utils.formatString('{lastName} {firstName} {middleName}', {
						lastName: subject.lastname,
						firstName: subject.firstname,
						middleName: subject.middlename
					});
				}
			}
		],
		idProperty: 'serial'
	}
});
