/**
 * @author Anatoly Deryshev
 * Cryptopro signature processing methods
 */
Ext.namespace("edi.sign");
Ext.override(edi.sign, {
	init: function() {
		var SYNC = "sync", ASYNC = "async", version = SYNC, __self = this,
			constants = {
				CADESCOM_CADES_BES: 1,
				CADESCOM_CADES_X_LONG_TYPE_1: 0x5d
			},
			config = {
				DETACHED_SIGN: true,
				CADES_TYPE: constants.CADESCOM_CADES_BES,
				CERTIFICATE_INCLUDE_WHOLE_CHAIN: false,
				USE_SIGN_TIME_ATTR: true,
				USE_SIGN_NAME_ATTR: false
			};
		if (Ext.isEdge) {
			version = ASYNC;
		}
		else if (Ext.isOpera) {
			version = Ext.operaVersion < 33 ? SYNC : ASYNC;
		}
		else if (Ext.isGecko && Ext.firefoxVersion) {
			version = Ext.firefoxVersion < 52 ? SYNC : ASYNC;
		}
		else if (Ext.isChrome) {
			version = Ext.chromeVersion < 35 || (Ext.isWindows && Ext.chromeVersion < 42) ? SYNC : ASYNC;
		}

		let completeLoading = function() {
			if (window.cryptoPro && "function" == typeof window.cryptoPro.init) {
				window.cryptoPro.init(config);
				edi.core.logMessage("CryptoPro client " + version + " version loaded", "info");
			}
			else {
				edi.core.logMessage("CryptoPro client " + version + " version could not be loaded!", "warn");
			}
		};

		if (version === ASYNC) {
			import('./cryptopro2.js').then(completeLoading).catch(completeLoading);
		}
		else {
			import('./cryptopro.js').then(completeLoading).catch(completeLoading);
		}

		__self.init = function() {
			edi.core.logMessage("CryptoPro loader is already initiated", "warn");
		};
	},
	isAvailable: function() {
		var available = window.cryptoPro && "function" == typeof window.cryptoPro.isPluginReady ? window.cryptoPro.isPluginReady() : false;
		if (!available) {
			edi.core.logMessage("Cryptopro signature plugin implementation is not available!", "warn");
		}
		return available;
	},
	displayNotAvailableMessage: function() {
		if (Ext.firefoxVersion >= 53) {
			edi.core.showError("error.cryptopro.firefox.extension.not.installed");
		}
		else if (Ext.operaVersion >= 33) {
			edi.core.showError("error.cryptopro.opera.extension.not.installed");
		}
		else {
			edi.core.showError("error.cryptopro.not.installed");
		}
	},
	/**
	 * Certificate check for GOST 34.10-2001, that will be called before sign process begin
	 * @param callback
	 * @param selectedCertObj
	 */
	checkCertificate: function(callback, selectedCertObj) {
		if (edi.constants.CRYPTOPRO && edi.constants.CRYPTOPRO.WARNINGS && edi.constants.CRYPTOPRO.WARNINGS.ALGORITHM && edi.constants.CRYPTOPRO.WARNINGS.ALGORITHM.ENABLED) {
			if (selectedCertObj) {
				var validTo = selectedCertObj.dateToParsed || selectedCertObj.validToDate;
				if (Ext.isObject(selectedCertObj.algorithm) && Ext.Array.contains(edi.constants.CRYPTOPRO.WARNINGS.ALGORITHM.OID, selectedCertObj.algorithm.oid) && validTo > edi.constants.CRYPTOPRO.WARNINGS.ALGORITHM.DATE) {
					var warnings = edi.utils.getCookie(edi.constants.CRYPTOPRO.WARNINGS.ALGORITHM.COOKIE), curTime = (new Date()).getTime(), i, skippedWarnings = {};
					warnings = warnings ? Ext.decode(warnings) : {};
					for (i in warnings) {
						if (warnings.hasOwnProperty(i) && (curTime - warnings[i] < edi.constants.CRYPTOPRO.WARNINGS.ALGORITHM.WARN_TIME)) {
							skippedWarnings[i] = warnings[i];
						}
					}
					if (!skippedWarnings[selectedCertObj.hash]) {
						edi.core.showWarn(edi.i18n.getMessage(edi.constants.CRYPTOPRO.WARNINGS.ALGORITHM.MSG), callback);
						skippedWarnings[selectedCertObj.hash] = curTime;
						edi.utils.setCookie(edi.constants.CRYPTOPRO.WARNINGS.ALGORITHM.COOKIE, Ext.encode(skippedWarnings));
					}
					else {
						callback();
					}
				}
				else {
					callback();
				}
			}
			else {
				callback();
			}
		}
		else {
			callback();
		}
	},
	/**
	 * Inintiates sign process
	 * @param    {Object}    properties
	 */
	setSignature: function(properties) {
		if (properties && properties.content && properties.certificate && properties.callback) {
			if (this.isAvailable()) {
				cryptoPro.signCreate(properties.content, properties.certificate, properties.callback, properties.docName, properties.options);
			}
		}
		else {
			edi.core.logMessage("Signature content, certificate or callback not passed!", "warn");
		}
	},
	/**
	 * Gets certificates collection from store
	 * @param    {Object}    properties
	 */
	getCertificates: function(properties) {
		if (properties && properties.callback) {
			if (this.isAvailable()) {
				cryptoPro.getCertificatesArray(properties.callback);
			}
		}
		else {
			edi.core.logMessage("Certificate selection callback not passed!", "warn");
		}
	}
});
edi.sign.init();