import { loadScript } from './loading';
import { sendRequest } from './rest.js';
import { createLoginPage } from './loginPage/loginPage';
import { createPasswordRecoveryPage } from './passwordRecoreryPage/passwordRecoveryPage';
import { createSuccessAuthLanding } from './successAuthLanding/successAuthLanding.js';
import { createPanel } from '@Components/panels';
import { renderConfirmationPage } from '../confirmation_ediweb/modules/renderConfirmationPage';
import { renderSuccessRecoveryPage } from './passwordRecoreryPage/renderSuccessRecoveryPage';
import { renderErrorRecoveryPage } from './passwordRecoreryPage/renderErrorRecoveryPage';
import { createLabel } from '@Components/fields';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons/Buttons';
import { createContainer } from '@UIkit/components/panels';

const LAYOUT = {};
const PAGES = {};
let login;
var requireNewPassword;

const changePageTo = function (route) {
	if (!edi.utils.getURLParams().key) {
		window.history.pushState({}, null, route?.path || '/');
	}
	var page = route?.page;
	if (typeof route?.page === 'function') {
		page = route?.page();
	}
	LAYOUT.cardPanel.layout.setActiveItem(page || PAGES.login.page);
};

/**
 * Select active page by url
 */
const selectPageByUrl = function () {
	let relativePath = window.location.pathname;
	let foundPage = Object.values(PAGES).filter((item) => item.path === relativePath)[0];

	changePageTo(foundPage);
};

const getTargetPage = function () {
	return window.location.pathname;
};

/**
 * Save url params into local storage
 */
const saveUrlParams = function () {
	let params = edi.utils.getURLParams();
	let newUrl = params.url || localStorage.getItem('url') || '';
	localStorage.setItem('url', newUrl);
	Ext.merge(edi.constants, {
		SSO_GLOBAL_VARIABLES: {
			url: newUrl,
			noRedirect: params.noredirect === 'true',
			key: params.key || '',
			backParam: params.backParam || '',
			runInFrame: params.runinframe === 'true',
			additionalInfoCode: params.infocode || ''
		}
	});
};

const showAdditionalInfo = function () {
	if (edi.constants.SSO_GLOBAL_VARIABLES.additionalInfoCode) {
		let infoKey = `sso.info.code.${edi.constants.SSO_GLOBAL_VARIABLES.additionalInfoCode}`;
		let infoMessage = edi.i18n.getMessage(infoKey);
		if (infoMessage !== infoKey) {
			edi.core.showInfo(infoMessage);
		}
	}
};

// Панель с баннером временно отключена, закомментировал чтобы исключить код из сборки

// const createPoaBanner = function () {
// 	const url = 'https://ediweb.com/ru-ru/media/news/ediweb-docs-one-month';
// 	return createContainer({
// 		cls: 'edi-sso-poa-banner',
// 		html: `<a href="${url}" target="_blank" alt='ediweb machine readable power of attorney'></a>`
// 	});
// };

// ===============================

const createViewportAndMainPanel = function () {
	LAYOUT.mainPanel = createPanel({
		cls: 'edi-main-panel sso-main-panel',
		region: 'center',
		width: '100%',
		height: '100%',
		//Отключена кнопка "Инструменты разработчика"
		//tools: (!edi.constants.SSO_GLOBAL_VARIABLES.runInFrame && edi.debug && edi.core.getDebugConfig().enabled)
		//	? [{
		//		xtype: 'button',
		//		cls: 'sso-build-toolbar',
		//		glyph: edi.constants.ICONS.BUILD,
		//		handler: edi.debug.showDebugWindow
		//	}]
		//	: undefined,
		layout: {
			type: 'hbox',
			align: 'stretch',
			pack: 'center'
		},
		items: [
			createPanel({
				layout: {
					type: 'vbox',
					align: 'stretch',
					pack: 'center'
				},
				items: [
					edi.constants.SSO_GLOBAL_VARIABLES.runInFrame
						? LAYOUT.cardPanel
						: createContainer({
								layout: {
									type: 'vbox',
									align: 'middle',
									pack: 'center'
								},
								items: [LAYOUT.cardPanel]
						  })
				]
			})
		]
		// listeners: {
		// 	boxready: function(comp) {
		// 		var size = Ext.getBody().getSize();
		// 		if (size.width < edi.constants.DEFAULT.VIEWPORT.minWidth || size.height < edi.constants.DEFAULT.VIEWPORT.minHeight) {//Force resize on initial loading
		// 			comp.setSize(size.width, size.height);
		// 		}
		// 	}
		// }
	});

	let viewportConf = Ext.apply(
		{
			cls: 'edi-login-form-displayed',
			items: [LAYOUT.mainPanel],
			header: false
		},
		edi.constants.DEFAULT.VIEWPORT
	);
	if (
		edi.constants.DEFAULT.BACKGROUND.ON_START &&
		((edi.constants.DEFAULT.BACKGROUND.IMG_LIST && edi.constants.DEFAULT.BACKGROUND.IMG_LIST.length) ||
			'MONTH' === edi.constants.DEFAULT.BACKGROUND.ROTATION)
	) {
		let bgNr, bgCls;
		if ('MONTH' === edi.constants.DEFAULT.BACKGROUND.ROTATION) {
			bgNr = new Date().getMonth() + 1;
			bgCls =
				edi.constants.DEFAULT.BACKGROUND.BASE_CLASS +
				' ' +
				edi.constants.DEFAULT.BACKGROUND.CLASS_NAME_PREFIX +
				bgNr;
		} else {
			bgNr = parseInt(edi.utils.getCookie(edi.constants.DEFAULT.BACKGROUND.COOKIE_NAME) || 0, 10);
			if (bgNr >= edi.constants.DEFAULT.BACKGROUND.IMG_LIST.length) {
				bgNr = 0;
			}
			bgCls =
				edi.constants.DEFAULT.BACKGROUND.BASE_CLASS +
				' ' +
				edi.constants.DEFAULT.BACKGROUND.CLASS_NAME_PREFIX +
				edi.constants.DEFAULT.BACKGROUND.IMG_LIST[bgNr];
		}
		viewportConf.cls = (viewportConf.cls ? viewportConf.cls + ' ' : '') + bgCls;

		if (!edi.constants.DEFAULT.HIDE_LOGIN_FOOTER && !edi.constants.SSO_GLOBAL_VARIABLES.runInFrame) {
			LAYOUT.footer = createPanel({
				cls: 'edi-viewport-footer',
				region: 'south',
				height: 32
			});
			viewportConf.items.push(LAYOUT.footer);
		}
		if (edi.constants.DEFAULT.SHOW_LOGIN_HEADER && !edi.constants.SSO_GLOBAL_VARIABLES.runInFrame) {
			LAYOUT.header = createPanel({
				cls: 'edi-viewport-header',
				region: 'north',
				align: 'center',
				maxWidth: 640,
				height: 48
			});
			viewportConf.items.push(LAYOUT.header);
		}
	}

	LAYOUT.viewPort = new Ext.Viewport(viewportConf);
	LAYOUT.viewPort.el.addCls('edi-login-form-displayed');
	if (edi.constants.SSO_GLOBAL_VARIABLES.runInFrame) {
		LAYOUT.viewPort.el.addCls('edi-sso-viewport-runtime');
	} else {
		LAYOUT.viewPort.el.removeCls('edi-sso-viewport-runtime');
	}
};

/**
 * Creates viewport, main panel and pages
 * @param	{Function}	[afterCreateCallback]
 */
const createViewport = function (afterCreateCallback) {
	PAGES.login = {
		path: '/',
		page: createLoginPage()
	};
	PAGES.confirmation = {
		path: '/confirmation',
		page: function () {
			return renderConfirmationPage(edi.utils.getURLParams().key);
		}
	};
	PAGES.passwordRecovery = {
		path: '/password_recovery.html',
		page: createPasswordRecoveryPage
	};
	PAGES.successAuthLanding = {
		page: createSuccessAuthLanding()
	};
	PAGES.successRecoveryPage = {
		page: renderSuccessRecoveryPage()
	};
	PAGES.errorRecoveryPage = {
		page: renderErrorRecoveryPage()
	};

	LAYOUT.cardPanel = createPanel({
		cls: 'edi-sso-card-panel',
		layout: 'card',
		items: [Object.values(PAGES).map((item) => item.page)]
	});

	createViewportAndMainPanel();

	selectPageByUrl();
	showAdditionalInfo();

	if (typeof afterCreateCallback === 'function') {
		afterCreateCallback();
	}
};

const createErrorBanner = function (afterCreateCallback) {
	LAYOUT.cardPanel = createPanel({
		cls: 'edi-sso-card-panel',
		layout: {
			type: 'vbox',
			align: 'center',
			pack: 'middle'
		},
		items: [
			createContainer({
				style: {
					textAlign: 'center'
				},
				html: 'Что-то пошло не так =(<br>Наши специалисты уже работают над этой проблемой.<br>Попробуйте зайти позже, а пока что вот Вам котик'
			}),
			createButton({
				margin: '32 0',
				cls: [BUTTON_CLS.primary],
				text: 'Попробовать снова',
				handler() {
					window.location.reload();
				}
			}),
			createLabel({
				cls: 'edi-sso-something-wrong-image'
			})
		]
	});

	createViewportAndMainPanel();

	if (typeof afterCreateCallback === 'function') {
		afterCreateCallback();
	}
};

/**
 * Loading
 * @param lang
 * @return {Promise<void>}
 */
const loadExtLocale = function (lang) {
	return new Promise((resolve) => {
		let extLocale;
		let languages = edi.constants?.LANGUAGES;
		if (lang && languages) {
			extLocale = languages[lang]?.EXT_LOCALE;
		} else if (!lang || !languages[lang]?.EXT_LOCALE) {
			extLocale = edi.constants.LANGUAGES['ENGLISH'].EXT_LOCALE;
		} else {
			resolve();
			return;
		}
		loadScript({
			path: 'js/ext74/locale/ext-lang-' + extLocale + '.js',
			success: () => resolve(),
			failure: () => resolve()
		});
	});
};

const i18n = function (key) {
	return edi.i18n.getMessage(key, undefined, undefined, undefined);
};

const afterLoadMessages = function (callback) {
	LAYOUT.footer ? edi.renderers.renderFooterContents(LAYOUT) : null;
	LAYOUT.header ? edi.renderers.renderHeaderContents(LAYOUT) : null;
	if (typeof callback === 'function') {
		callback();
	}
};

/**
 * Check authenticate
 * @param {Function} nextChainStep
 * @param {Function} ediAuthToken
 * @param {Function} callback
 */
const checkAuth = function (nextChainStep, ediAuthToken, callback) {
	let url = edi.utils.compileURL(edi.rest.services.SSO.AUTHENTICATE, {
		backParam: edi.constants.SSO_GLOBAL_VARIABLES.backParam
	});

	sendRequest(url, 'POST', '{}')
		.then((resp) => {
			let data = resp.data;
			setRequireNewPassword(data.requireNewPassword);
			if (data.success && data.nextChainStepRequired) {
				'function' == typeof nextChainStep ? nextChainStep(data) : null;
			} else if (resp.data.success && data.ediAuthToken) {
				'function' == typeof ediAuthToken ? ediAuthToken(data) : null;
			} else {
				callback();
			}
		})
		.catch(() => callback());
};

/**
 * Tries to use current saved session and redirect to target platform
 * and if it fails starts application (download translations and create main layout with pages)
 */
const initApp = function () {
	saveUrlParams();
	let loadApp = async function (afterStartCallback) {
		edi.core.loadLocalizationGroups.push('initMini');
		edi.core.loadLocalizationGroups.push('ediweb_init');
		edi.core.loadLocalizationGroups.push('debug');
		edi.core.loadLocalizationGroups.push('sso_client');
		let language = edi.i18n.getLanguage();
		await loadExtLocale(
			edi.constants.LANGUAGES[language] && edi.constants.LANGUAGES[language].EXT_LOCALE
				? language
				: edi.constants.DEFAULT.LANGUAGE
		);

		Ext.getBody().removeCls('initial-loader');
		edi.i18n.loadMessages(null, false, (isSuccess) => {
			setDocumentTitle(i18n('document.title'));
			if (isSuccess) {
				createViewport(afterStartCallback);
			} else {
				createErrorBanner(afterStartCallback);
			}
			afterLoadMessages();
		});
	};
	checkAuth(
		function (data) {
			loadApp(() => {
				changePageTo(PAGES.login);
				PAGES.login.switchToNextStep(data.nextChainStep[0]);
			});
		},
		function (data) {
			loadApp(() => {
				let url = edi.constants.SSO_GLOBAL_VARIABLES.url;
				if (edi.constants.SSO_GLOBAL_VARIABLES.runInFrame === true) {
					let pathArray = url.split('/');
					let protocol = pathArray[0];
					let host = pathArray[2];
					window.parent.postMessage({ newAuthToken: data.ediAuthToken }, `${protocol}//${host}`);
				} else if (getTargetPage() === '/') {
					if (url && !edi.constants.SSO_GLOBAL_VARIABLES.noRedirect) {
						url = new URL(url);
						url.searchParams.append('token', data.ediAuthToken);
						window.location.href = url;
					} else {
						edi.constants.SSO_GLOBAL_VARIABLES.noRedirect = false;
						changePageTo(PAGES.successAuthLanding);
					}
				} else {
					selectPageByUrl();
				}
			});
		},
		loadApp
	);
};

const setLogin = function (value) {
	login = value;
};

const getLogin = function () {
	return login;
};

const getRequireNewPassword = function () {
	return requireNewPassword;
};

const setRequireNewPassword = function (value) {
	requireNewPassword = value;
};

const setDocumentTitle = function (title) {
	if (title) {
		document.title = title;
	}
};

export {
	initApp,
	changePageTo,
	checkAuth,
	PAGES,
	LAYOUT,
	i18n,
	setLogin,
	getLogin,
	getRequireNewPassword,
	setRequireNewPassword
};
