import { sendRequest } from '../rest';
import { changePageTo, i18n, PAGES } from '../mainLayout';
// import {createLink} from "../../components/buttons";
import { createLabel } from '../../components/fields';
import { createPanel } from '../../components/panels';
import { createContainer } from '../../components/miscComponents';

import { BUTTON_CLS, createButton } from '@UIkit/components/buttons/Buttons';

const createSuccessAuthLanding = function () {
	let errorHandler = function (resp) {};

	let style = {
		width: 'auto',
		display: 'block',
		textAlign: 'center'
	};

	return createPanel({
		name: 'seccessAuth',
		cls: 'edi-login-page-panel',
		width: edi.constants.DEFAULT.VIEWPORT.minWidth,
		height: edi.constants.DEFAULT.VIEWPORT.minHeight,
		items: [
			createContainer({
				style: style,
				items: [
					createLabel({
						text: i18n('sso.success.auth.landing.label')
					}),
					createButton({
						cls: [BUTTON_CLS.secondary],
						text: i18n('sso.success.auth.change.user'),
						margin: '20 0 0 0',
						handler: function () {
							sendRequest(edi.rest.services.SSO.LOGOUT, 'POST', undefined)
								.then((resp) => {
									if (resp && resp.data) {
										changePageTo(PAGES.login);
									}
								})
								.catch(errorHandler);
						}
					})
				]
			})
		]
	});
};

export { createSuccessAuthLanding };
