import { createForm, createPanel } from '../../components/panels';
// import {createButton} from "../../components/buttons";
import { changePageTo, PAGES } from '../mainLayout';
import { createContainer } from '@Components/miscComponents';

import { BUTTON_CLS, createButton } from '@UIkit/components/buttons/Buttons';

const renderSuccessRecoveryPage = function () {
	let logo = createContainer({
		cls: 'edi-ediweb-header-logo'
	});
	let form = createForm({
		cls: 'edi-form edi-login-form',
		items: [
			logo,
			{
				xtype: 'container',
				cls: 'result-password-recovery',
				html: ''
			},
			edi.components.createLabel({
				cls: 'new-password-saved',
				text: edi.i18n.getMessage('new.password.saved', null, null, null)
			}),
			createButton({
				cls: [BUTTON_CLS.primary],
				text: edi.i18n.getMessage('sso.form.btn.login'),
				handler: function () {
					localStorage.removeItem('key');
					changePageTo(PAGES.login);
				}
			})
		]
	});
	return createPanel({
		cls: 'edi-form edi-login-page-panel edi-success-save-password-form',
		width: edi.constants.DEFAULT.LOGIN_FORM_WIDTH,
		minWidth: edi.constants.DEFAULT.LOGIN_FORM_WIDTH,
		minHeight: 340,
		items: [form]
	});
};

export { renderSuccessRecoveryPage };
