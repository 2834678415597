import { createForm, createPanel } from '../../components/panels';
// import {createButton} from "../../components/buttons";
import { changePageTo, PAGES } from '../mainLayout';
import { createContainer } from '@Components/miscComponents';

import { BUTTON_CLS, createButton } from '@UIkit/components/buttons/Buttons';

const renderErrorRecoveryPage = function () {
	let logo = createContainer({
		cls: 'edi-ediweb-header-logo'
	});
	let form = createForm({
		cls: 'edi-form edi-login-form',
		items: [
			logo,
			{
				xtype: 'container',
				cls: 'result-error-password-recovery',
				html: ''
			},
			edi.components.createLabel({
				cls: 'new-password-not-saved',
				html: Ext.String.format(
					'<b>{0}</b><br><div>{1}</div>',
					edi.i18n.getMessage('cant.save.password'),
					edi.i18n.getMessage('sso.password.recovery.contact.support.or.try.later')
				)
			}),
			createButton({
				margin: '24 0 0 0',
				cls: [BUTTON_CLS.secondary],
				glyph: edi.constants.ICONS.ARROW_BACK,
				// width: '100%',
				text: edi.i18n.getMessage('ediweb.back.btn', null, null, null),
				handler: function () {
					changePageTo(PAGES.passwordRecovery);
				}
				// cls: "edi-login-register-button edi-ediweb-submit-button edi-login-back-button"
			})
		]
	});
	return createPanel({
		cls: 'edi-form edi-login-page-panel edi-error-save-password-form',
		width: edi.constants.DEFAULT.LOGIN_FORM_WIDTH,
		minWidth: edi.constants.DEFAULT.LOGIN_FORM_WIDTH,
		minHeight: 358,
		items: [form]
	});
};

export { renderErrorRecoveryPage };
