import { sendRequest } from '../rest';
import { i18n, LAYOUT, changePageTo, PAGES } from '../mainLayout';
// import {createButton} from "../../components/buttons";
import { createLabel } from '../../components/fields';
import { createForm, createPanel } from '../../components/panels';
// import {createFieldsBlock} from "../components/components";
import { createContainer } from '../../components/miscComponents';

import { createFieldBlock } from '@UIkit/components/panels/FieldBlock/FieldBlock';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons/Buttons';
import { createTextField } from '@UIkit/components/fields/Text/Text';
import { FIELD_CLS } from '@UIkit/components/fields/Base/Base';

const createRecoveryRequestForm = function () {
	var sendAgainBtn, infoContainer;
	var setInfo = function (msg) {
		form.addCls('success-password-restore');
		infoContainer.setVisible(true);
		sendAgainBtn.setDisabled(true);
		infoContainer.setText(msg, false);
	};
	let requestRecovery = function () {
		const cardPanel = LAYOUT.cardPanel;
		cardPanel.setLoading(true);

		let errorHandler = function (resp) {
			cardPanel.setLoading(false);
			var errorMessage = edi.utils.getObjectProperty(resp, 'typeError');
			const isErrorDefine = errorMessage.length && i18n(errorMessage) !== errorMessage;

			if (isErrorDefine) {
				errorLabel.setText(i18n(errorMessage));
				loginField.markInvalid('');
			} else {
				loginField.hide();
				recoverBtn.hide();
				backBtn.hide();
				subtitleLabel.hide();
				errorPasswordRestorePanel.show();
			}
		};

		let login = form.getForm().getValues().login || '';
		let url = edi.utils.formatString(edi.rest.services.SSO.REQUEST_RECOVERY, { login });
		let payload = Ext.encode({ login });
		sendRequest(url, 'POST', payload)
			.then((resp) => {
				if (!resp || !resp.success) {
					errorHandler(resp);
					return;
				}
				this.ownerCt.ownerCt.setHeight(this.ownerCt.ownerCt.maxHeight);
				cardPanel.setLoading(false);
				loginField.hide();
				recoverBtn.hide();
				backBtn.hide();
				subtitleLabel.setText(edi.i18n.getMessage('sso.password.recovery.check.email'));
				successPasswordRestorePanel.show();
				setInfo(edi.i18n.getMessage('ediweb.resend.timer'));
				const userEmail = resp?.data?.email || '';
				if (userEmail) {
					successPasswordRestorePanelLabel?.setHtml(
						edi.i18n.getMessage('sso.password.recovery.send.link.for.recover', [userEmail])
					);
				}

				setTimeout(function () {
					infoContainer.setText('');
					sendAgainBtn.setDisabled(false);
				}, 20000);

				sendAgainBtn.show();
			})
			.catch(errorHandler);
	};

	let loginField = createTextField({
		cls: [FIELD_CLS.big],
		margin: '32 0 0 0',
		// cls: "edi-login-form__field password-recovery-login",
		fieldLabel: i18n('login.form.login'),
		name: 'login',
		// width: '100%',
		listeners: {
			specialkey: function (field, e) {
				if (e.getKey() === e.ENTER) {
					requestRecovery();
				}
			},
			change: function () {
				errorLabel.setText('');
			},
			afterrender: (field) => {
				field.focus(false, 200);
			}
		}
	});

	let successPasswordRestorePanelLabel;
	var successPasswordRestorePanel = createPanel({
		cls: 'edi-onboarding-continue-register',
		hidden: true,
		items: [
			{
				xtype: 'container',
				cls: 'edi-ediweb-envelope',
				html: ''
			},
			(successPasswordRestorePanelLabel = createLabel({
				cls: 'edi-ediweb-envelope-label',
				html: edi.i18n.getMessage('ediweb.link.password.recovery')
			}))
		]
	});

	const errorPasswordRestorePanel = createPanel({
		cls: 'edi-onboarding-continue-register edi-restore-password-error',
		hidden: true,
		items: [
			{
				xtype: 'container',
				cls: 'edi-restore-password-error__img',
				html: ''
			},
			createLabel({
				cls: 'edi-restore-password-error__title',
				html: edi.i18n.getMessage('sso.password.recovery.request.failed')
			}),
			createLabel({
				cls: 'edi-restore-password-error__subtitle',
				html: edi.i18n.getMessage('sso.password.recovery.contact.support.or.try.later')
			}),
			createButton({
				margin: '16 0 0 0',
				cls: [BUTTON_CLS.secondary],
				glyph: edi.constants.ICONS.ARROW_BACK,
				text: edi.i18n.getMessage('ediweb.back.btn'),
				handler: function () {
					errorPasswordRestorePanel.hide();
					loginField.show();
					recoverBtn.show();
					backBtn.show();
					subtitleLabel.show();
				}
				// cls: "edi-login-register-button edi-ediweb-submit-button edi-login-back-button",
				// width: "100%",
			})
		]
	});

	var subtitleLabel = edi.components.createLabel({
		cls: 'edi-ediweb-login-subtitle',
		text: edi.i18n.getMessage('password.reset.request')
	});

	let errorLabel = createLabel({
		cls: 'edi-login-error-label',
		text: '',
		listeners: {
			//Эвент который стрельнет при setText
			resize: (label) => {
				const isError = !!label.text;
				label.toggleCls('label-empty', !isError);
				form.updateLayout();
				recoverBtn.setDisabled(isError);
			}
		}
	});
	let errorContainer = createContainer({
		cls: 'edi-login-error-container edi-login-form__label',
		items: [errorLabel]
	});

	let recoverBtn = createButton({
		margin: '24 0 0 0',
		cls: [BUTTON_CLS.primary],
		text: i18n('password.recovery.send'),
		handler: requestRecovery
		// cls: "edi-login-submit-button edi-ediweb-submit-button",
		// width: "100%",
	});

	sendAgainBtn = createButton({
		margin: '16 0 0 0',
		cls: [BUTTON_CLS.primary],
		text: edi.i18n.getMessage('ediweb.send.again.btn'),
		handler: requestRecovery,
		hidden: true,
		formBind: true
		// cls: "edi-login-submit-button edi-ediweb-submit-button",
		// width: "100%"
	});

	let backBtn = createButton({
		margin: '16 0 0 0',
		cls: [BUTTON_CLS.secondary],
		glyph: edi.constants.ICONS.ARROW_BACK,
		text: edi.i18n.getMessage('ediweb.back.btn'),
		handler: function () {
			changePageTo(PAGES.login);
		}
		// cls: "edi-login-register-button edi-ediweb-submit-button edi-login-back-button",
		// width: "100%",
	});

	let logo = createContainer({
		cls: 'edi-ediweb-header-logo'
	});

	let form = createForm({
		// cls: "edi-form edi-recovery-password-form",
		items: [
			logo,
			subtitleLabel,
			loginField,
			errorContainer,
			successPasswordRestorePanel,
			errorPasswordRestorePanel,
			sendAgainBtn,
			recoverBtn,
			backBtn,
			createContainer({
				cls: 'edi-login-info-container',
				items: [
					(infoContainer = createLabel({
						text: '',
						cls: 'edi-login-info-label'
					}))
				]
			})
		]
	});
	return form;
};

export { createRecoveryRequestForm };
