import {createGrid} from "@Components/grid";
import {createModalPanel} from "@Components/panels";

/**
 * @author Anatoly Deryshev
 * Signature base methods, should be overridden by exact implementation
 */
Ext.namespace("edi.sign");
edi.sign = new function() {
	/**
	 * Method for any signature initialisation processes
	 */
	this.init = function() {
		edi.core.handleException("This method should be overridden");
	};
	/**
	 * This method should be used for checking if signing is possible. Should be overridden in exact implementation
	 * @returns {boolean}
	 */
	this.isAvailable = function(properties) {
		return false;
	};
	/**
	 * Displays error message, should be overriden to display needed message
	 */
	this.displayNotAvailableMessage = function() {
		edi.core.showError("error.sign.plugin.not.found");
	};
	/**
	 * Sets signature
	 * @param    {Object}    properties     should contain at least properties - content, certificate, callback
	 */
	this.setSignature = function(properties) {
		edi.core.handleException("This method should be overridden");
	};
	/**
	 * Reads certificates from certificates store
	 * @param    {Object}    properties     should contain at least property - callback
	 */
	this.getCertificates = function(properties) {
		edi.core.handleException("This method should be overridden");
	};
	/**
	 * Implements certificate checks, that will be called before sign process begin
	 * @param callback
	 * @param selectedCertObj
	 */
	this.checkCertificate = function(callback, selectedCertObj) {
		"function" == typeof callback ? callback() : null;
	};
	/**
	 * Get active certificates collection
	 * @param    {Function}    callback
	 * @param    {Array}       certificates
	 * @param    {String}      type                document (content) type
	 */
	this.getActiveCertificates = function(callback, certificates, type) {
		var res = [], activeCert;
		var processCertificates = function(data) {
			var cert;
			if (data && data.data && data.data.length && activeCert) {
				cert = edi.utils.findObjectInArray(data.data, edi.constants.CERTIFICATE.ACTIVE_SEARCH_PARAMS.STORE, activeCert[edi.constants.CERTIFICATE.ACTIVE_SEARCH_PARAMS.DB]);
				if (cert) {
					res.push({
						cert: cert,
						data: activeCert,
						availableDocTypesForSignature: activeCert.availableDocTypesForSignature
					});
				}
			}
			"function" == typeof callback ? callback(res) : null;
		};
		edi.rest.sendRequest(edi.utils.formatString(edi.rest.services.USER.SELF.CERTIFICATE.ACTIVE.GET, {
			type: type
		}, true), "GET", null, function(responseData) {
			if (responseData.data && edi.sign.isAvailable()) {
				activeCert = responseData.data;
				if (!certificates || !certificates.length) {
					edi.sign.getCertificates({
						callback: processCertificates
					});
				}
				else {
					processCertificates({
						data: certificates
					});
				}
			}
			else {
				"function" == typeof callback ? callback(res) : null;
			}
		}, function() {
			"function" == typeof callback ? callback(res) : null;
		});
	};
	/**
	 * Displays certificate selection dialog
	 * @param    {Function}    callback          callback that will have selected certificate as parameter passed on selection
	 * @param    {Function}    refuse            callback that will be called on user refuse from selection(or selection window normal close, in this case true will be passed as parameter)
	 * @param    {Boolean}     dontUseDefault    true to skip usage of certificate set by default in user settings
	 * @param    {String}      contentType       document (content) type
	 * @param    {Boolean}     showActiveCertConfirm    show confirm modal with active certificates (in false value - force hiding this modal)
	 */
	this.selectCertificate = function(callback, refuse, dontUseDefault, contentType, showActiveCertConfirm) {
		edi.sign.getCertificates({
			callback: function(data) {
				var successClose = false;
				if (data && data.data && data.data.length) {
					var certificates = data.data;
					var showCertificatesSelect = function(filteredCertificates) {
						filteredCertificates = filteredCertificates || certificates;

						var columnsName = 'certificate';
						if (filteredCertificates && filteredCertificates[0] && undefined !== filteredCertificates[0].availableDocTypesForSignature) {
							columnsName = 'certificate_sign';
						}

						var modal, grid, checkedCerts, gridData;

						grid = createGrid({
							storeConfig: {
								model: edi.models.getModel("CERTIFICATE"),
								listeners: {
									datachanged: function() {
										modal ? modal.setLoading(false) : null;
									}
								}
							},
							gridConfig: {
								columns: edi.columns.get(columnsName),
								listeners: {
									select: function(comp, record) {
										var data = record.getData();
										"function" == typeof callback ? callback(data.cert, data) : null;
										successClose = true;
										modal.close();
									}
								},
								height: 400
							}
						});

						modal = createModalPanel({
							cls: "edi-modal-form edi-modal-certificate-selector",
							width: edi.constants.DEFAULT.MODAL.WIDTH_MAX,
							title: edi.i18n.getMessage('certificate.select.title'),
							items: [grid],
							listeners: {
								close: function() {
									"function" == typeof refuse ? refuse(successClose) : null;
								}
							}
						});
						modal.show();
						modal.setLoading(edi.i18n.getMessage("certificate.validation.message"));

						checkedCerts = edi.utils.parseCertificateData(filteredCertificates);
						gridData = [];
						for (var i = 0; i < checkedCerts.length; i++) {
							gridData.push(edi.models.createInstance("CERTIFICATE", checkedCerts[i]));
						}

						grid.getStore().setProxy({
							type: 'pagingmemory',
							enablePaging: true,
							data: gridData
						});
						grid.getStore().reload();

						modal.setLoading(false);
					};

					if (!edi.constants.CERTIFICATE.ALLOW_ACTIVE || dontUseDefault) {
						showCertificatesSelect();
					}
					else {
						edi.sign.getActiveCertificates(function(activeCertificates) {
							var continueFn = function() {
								if (1 === activeCertificates.length) {
									"function" == typeof callback ? callback(activeCertificates[0].cert, activeCertificates[0].data) : null;
								}
								else {
									showCertificatesSelect(activeCertificates);
								}
							};
							if (activeCertificates && activeCertificates.length) {
								if (showActiveCertConfirm) {
									edi.core.confirm(null, "use.active.certificate.to.sign.confirmation", continueFn, function() {
										showCertificatesSelect();
									}, undefined, function(){
										"function" == typeof refuse ? refuse(successClose) : null;
									});
								}
								else {
									continueFn();
								}
							}
							else if (!edi.constants.CERTIFICATE.ONLY_ACTIVE) {
								showCertificatesSelect();
							}
							else {
								edi.core.showError(edi.constants.CERTIFICATE.NO_ACTIVE_ERROR);
								"function" == typeof refuse ? refuse(successClose) : null;
							}
						}, certificates, contentType);
					}
				}
				else if (data?.error) {
					let errorText = JSON.stringify(data.error).toLowerCase();
					let ignoreThisError = (edi.constants.cryptoProIgnoredErrorCodes || []).some(code => {
						return errorText.indexOf(code.toLowerCase()) !== -1;
					});
					if (ignoreThisError) {
						"function" == typeof refuse ? refuse(successClose) : null
					}
					else {
						edi.core.showError(edi.utils.formatComplexServerError(data.error), function() {
							"function" == typeof refuse ? refuse(successClose) : null
						});
					}
				}
				else {
					edi.core.showError("error.cryptopro.no.certificates", function() {
						"function" == typeof refuse ? refuse(successClose) : null
					});
				}
			}
		});
	}
}();