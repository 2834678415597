import '@UIkit/components/fields/Combobox/Combobox';
import './models';

import './ComboboxCerts.scss';

const baseCls = 'ui-comboboxfield-certs';

Ext.define('UI.components.ComboboxCertsField', {
	extend: 'UI.components.ComboboxField',
	baseCls,

	displayField: 'display_value',
	name: 'cert',
	valueField: 'cert',

	initComponent: function () {
		var me = this;
		me.callParent();

		me.on('afterrender', function (cmp) {
			const picker = cmp.getPicker();

			picker.on('viewlist', function (__self, record, itemEl) {
				const wrapEl = itemEl.select(`[data-ref="wrapEl"]`).first();

				var tpl = new Ext.XTemplate(
					'<tpl if="subject.commonName">',
					'<span class="ui-boundlist-item-subtext">{subject.commonName}</span>',
					'</tpl>'
				);
				var el = tpl.append(wrapEl, record.getData());
			});
		});

		me.on('focus', function (comp) {
			if (edi.sign.isAvailable()) {
				edi.sign.getCertificates({
					callback: function (data) {
						let storeComboCert = edi.stores.createInlineStore(
							edi.utils.parseCertificateData(data.data),
							'CERTIFICATE'
						);

						comp.bindStore(storeComboCert);
						comp.expand();
					}
				});
			}
		});
	}
});

const createComboCerts = (config) => new UI.components.ComboboxCertsField(config);

export { createComboCerts };
