Ext.namespace('edi.sign');

Ext.apply(edi.sign, {
	selectCertificate: function (callback, refuse) {
		var successClose = false;
		if (!edi.sign.isAvailable()) {
			edi.core.showWarn('error.cryptopro.not.installed');
			'function' == typeof refuse ? refuse(successClose) : null;
			return;
		}

		edi.sign.getCertificates({
			callback: function (data) {
				if (data && data.data && data.data.length) {
					var certificates = data.data;
					'function' == typeof callback ? callback(certificates) : null;
				} else if (data && data.error) {
					edi.core.showError(edi.utils.formatComplexServerError(data.error), function () {
						'function' == typeof refuse ? refuse(successClose) : null;
					});
				} else {
					edi.core.showError('error.cryptopro.no.certificates', function () {
						'function' == typeof refuse ? refuse(successClose) : null;
					});
				}
			}
		});
	}
});
